import React, { useEffect, useMemo } from 'react';
import { rules, operators, RuleId } from '@pushologies/segments-service/data';
import { Rule } from '~store/create-segment';
import { getRuleInvalidId } from '~store/create-segment/helpers';
import { useStoreActions } from '~store/hooks';
import { Select, SelectOption } from '~components/select';
import { RuleArticle } from './styles';
import { ValueInput } from './value-input';
import { isInvalidValue } from './validation';
import { RuleIcon } from './icon';
import { RuleActions } from './actions';

const ruleNotes: Partial<Record<RuleId, string>> = {};

interface Props {
  rule: Rule;
  minified?: boolean;
}

export const SegmentRule: React.FC<Props> = ({ rule, minified }) => {
  const { setRuleOperator, setRuleValue, addInvalidRule, removeInvalidRule } = useStoreActions(
    ({ createSegment }) => createSegment
  );
  const ruleInfo = rules[rule.segmentRule.ruleId];

  const handleOperatorChange = (selected: SelectOption[]) => {
    setRuleOperator({ id: rule.id, operator: selected[0]?.value });
    setRuleValue({ id: rule.id, value: null });
  };

  useEffect(() => {
    const id = getRuleInvalidId(rule, 'operator');
    if (!rule.segmentRule.operator) {
      addInvalidRule(id);
    } else {
      removeInvalidRule(id);
    }
  }, [rule.segmentRule.operator]);

  useEffect(() => {
    const id = getRuleInvalidId(rule, 'value');
    if (isInvalidValue(rule.segmentRule)) {
      addInvalidRule(id);
    } else {
      removeInvalidRule(id);
    }
  }, [rule.segmentRule.value]);

  return useMemo(
    () => (
      <RuleArticle
        data-testid="rule"
        minified={minified}
        className={ruleNotes[rule.segmentRule.ruleId] ? 'withNote' : ''}
      >
        {ruleNotes[rule.segmentRule.ruleId] && <h6 className="note">{ruleNotes[rule.segmentRule.ruleId]}</h6>}
        <section className="header">
          <RuleIcon id={rule.segmentRule.ruleId} />
          <h1 data-testid="ruleName" className="rule-name">
            {ruleInfo.name}
          </h1>
        </section>
        <p data-testid="ruleDescription">{ruleInfo.description}</p>
        <section className="inputs">
          <div className="inputs-wrapper">
            <div data-testid="ruleOperator" className="inputs__operator">
              <Select
                id={rule.id}
                testId="selectOperatorBtn"
                value={[operators[rule.segmentRule.operator]].filter(Boolean)}
                options={ruleInfo.allowedOperators}
                onChange={handleOperatorChange}
                placeholder="select operator"
                inValid={!rule.segmentRule.operator}
              />
            </div>
            <div data-testid="ruleValue" className="inputs__value">
              <ValueInput rule={rule} />
            </div>
          </div>
          <RuleActions rule={rule} />
        </section>
      </RuleArticle>
    ),
    [rule]
  );
};
